export function downloadJson(filename: string, obj: object) {
  const blob = new Blob([stringifyJson(obj)], {
    type: 'application/json',
  });
  downloadBlob(filename, blob);
}

export function stringifyJson(obj: object): string {
  return JSON.stringify(obj, null, 2);
}

export function downloadBlob(filename: string, data: Blob | MediaSource): void {
  const hiddenElement = document.createElement('a');
  const url = window.URL || window.webkitURL;
  hiddenElement.href = url.createObjectURL(data);
  hiddenElement.target = '_blank';
  hiddenElement.download = filename;
  hiddenElement.click();
  hiddenElement.remove();
}
