import { assertUnreachable } from '@sgme/fp';

import type { WsQuery, WsSubscriptionParameter } from '@/types/model/ws/query.ts';
import { getConfig } from '@/core/config/config.ts';
import { removeOneFromArray } from '@/core/utils/arrays.ts';
import { WebsocketConnection, type WsEventEmitter } from '@/core/ws/WebsocketConnection.ts';
import { store, type AppStore } from '@/store/store.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { handleGuiNotificationThunk } from '@/store/slices/uiSlice.ts';
import { fetchCompanies, fetchMarkets } from '@/store/async-thunks/api-thunks.ts';
import { fetchAlerts } from '@/store/async-thunks/ws-thunks.ts';
import { SubscriptionManager } from '@/web/components/guardian/SubscriptionManager.ts';
import { getAuthorizationToken } from '@/web/sgwt/sgwtConnect.ts';
import { alertDb } from '@/data/alertDb/alertDb.ts';

export async function websocketLoader() {
  const dispatch = store.dispatch;
  const wsConnection = await initWsConnection(store);

  await Promise.all([
    dispatch(fetchCompanies()),
    dispatch(fetchMarkets()),
    dispatch(fetchAlerts({ wsConnection })),
  ]);

  return {
    wsConnection,
    subscriptionManager: new SubscriptionManager(store, wsConnection),
  };
}

async function initWsConnection(store: AppStore) {
  const wsConnection = await WebsocketConnection.create(getConfig().wsUrl);
  subscribeToUpdates(wsConnection.eventEmitter, store);
  monitorSubscription(wsConnection.eventEmitter);
  const message: WsQuery = {
    type: 'REQUEST',
    payload: {
      id: 0,
      operation: 'AUTHENTICATE_GUI',
      parameter: {
        token: getAuthorizationToken()!,
      },
    },
  };
  if (import.meta.env.VITE_CONFIG_ENVIRONMENT === 'devci') {
    const searchParameters = new URLSearchParams(window.location.search);
    const db = alertDb;
    if (searchParameters.has('melody')) {
      const notes = searchParameters.get('melody');
      db.guiNotifications = [...notes!].map((note, index) => ({
        type: 'TOAST' as const,
        title: `note ${note}`,
        sound: `Xylophone.hardrubber.ff.${note}5.stereo` as unknown as 'SOUND1',
        delay: 1000 + index * 10,
      }));
    }
    // @ts-ignore
    message.payload.parameter.alertDb = db;
  }

  await wsConnection.sendAndWait(message);
  wsConnection
    .sendAndWait({
      type: 'REQUEST',
      payload: {
        id: 1,
        operation: 'SUBSCRIBE',
        parameter: {
          subject: 'GUI_NOTIFICATION',
        },
      },
    })
    .catch(_ => {
      console.error('Backend does not implement notifications');
    });

  return wsConnection;
}

function subscribeToUpdates(eventEmitter: WsEventEmitter, store: AppStore) {
  eventEmitter.on('notification', notif => {
    if (notif.type === 'UPDATE' || notif.type === 'ADD') {
      const subject = notif.subject;
      switch (subject) {
        case 'ALERT':
          store.dispatch(mainSlice.actions.updateAlert(notif.payload));
          break;
        case 'BREACH':
          store.dispatch(mainSlice.actions.updateBreach(notif.payload));
          break;
        case 'EVENT':
          store.dispatch(mainSlice.actions.updateEvent(notif.payload));
          break;
        case 'GUI_NOTIFICATION':
          store.dispatch(handleGuiNotificationThunk(notif.payload));
          break;
        default:
          assertUnreachable(subject, `Unknown subject ${subject}`);
      }
    }
  });
}

let subscriptions: WsSubscriptionParameter[] = [];

function displaySubscriptions(subscriptions: WsSubscriptionParameter[]) {
  console.table(subscriptions);
}

function monitorSubscription(eventEmitter: WsEventEmitter) {
  eventEmitter.on('onSubscribe', params => {
    subscriptions.push(params);
    displaySubscriptions(subscriptions);
  });
  eventEmitter.on('onUnsubscribe', params => {
    subscriptions = removeOneFromArray(
      subscriptions,
      p => JSON.stringify(p) === JSON.stringify(params),
    );
    displaySubscriptions(subscriptions);
  });
}
