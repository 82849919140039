import { useState } from 'react';
import { Button } from '@sgme/ui';
import { Allotment, LayoutPriority } from 'allotment';
import clsx from 'clsx';

import { useAppSelector } from '@/store/hooks.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { useModal } from '@/web/components/bootstrap/modal/ModalContext.tsx';
import { SuspenseWrapper } from '@/web/components/common/SuspenseWrapper.tsx';
import { AlertsTable } from '@/web/components/guardian/AlertsTable.tsx';
import { CommentsPanel } from '@/web/components/guardian/CommentsPanel.tsx';
import { EventsTable } from '@/web/components/guardian/EventsTable.tsx';
import { SnoozeModal } from '@/web/components/guardian/snooze/SnoozeModal.tsx';
import { useSnoozed } from '@/web/components/guardian/snooze/useSnoozed.tsx';
import { downloadJson } from '@/web/download.ts';

export interface ExpandedDetails {
  expandedAlertId?: number;
  expandedBreachId?: number;
}

export interface MainPageProps extends ExpandedDetails {}

export function MainPage(props: MainPageProps) {
  const [dragging, setDragging] = useState(false);

  function handleDragStart() {
    setDragging(true);
  }

  function handleDragEnd() {
    setDragging(false);
  }

  return (
    <SuspenseWrapper>
      <Allotment vertical={true} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Allotment.Pane className="d-flex flex-column" preferredSize={'70%'}>
          <TopPanel {...props} />
        </Allotment.Pane>
        <Allotment.Pane className="d-flex flex-column">
          <BottomPanel className={clsx({ dragging })} />
        </Allotment.Pane>
      </Allotment>
    </SuspenseWrapper>
  );
}

interface TopPanelProps extends ExpandedDetails {}

function TopPanel(props: TopPanelProps) {
  return (
    <>
      <ToolsPanel />
      <div className="grid-container">
        <AlertsTable {...props} />
      </div>
    </>
  );
}

interface BottomPanelProps {
  className: string;
}

function BottomPanel({ className }: BottomPanelProps) {
  const [dragging, setDragging] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  function handleDragStart() {
    setDragging(true);
  }

  function handleDragEnd() {
    setDragging(false);
  }

  const details = useAppSelector(mainSlice.selectors.selectedDetails);

  function toggleFullscreen() {
    setFullscreen(fullscreen => !fullscreen);
  }

  return (
    <>
      <Allotment
        vertical={false}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        className={clsx({ fullscreen })}
      >
        <Allotment.Pane className={clsx('drag-panel d-flex flex-column', className)}>
          <article className="px-16px px-lg-24px py-16px flex-grow-1 flex-shrink-0 d-flex flex-column gap-12px">
            <div className="flex-between">
              <h2 className="h5">
                {details?.breachId
                  ? `Events for alert ${details?.alertId} - breach ${details?.breachId}`
                  : 'Events'}
              </h2>
              <div className="d-flex gap-4px">
                <SnoozeButton alertId={details?.alertId} breachId={details?.breachId} />

                <Button
                  iconPosition="end"
                  purpose={fullscreen ? 'discreet' : 'flat'}
                  type="button"
                  onClick={toggleFullscreen}
                >
                  {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
                  <i className="icon">{fullscreen ? 'fullscreen_exit' : 'fullscreen'}</i>
                </Button>
              </div>
            </div>

            <EventsTable />
          </article>
        </Allotment.Pane>
        <Allotment.Pane
          className={clsx('p-2 drag-panel vertical', { dragging })}
          preferredSize={420}
          priority={LayoutPriority.High}
        >
          <CommentsPanel alertId={details?.alertId} breachId={details?.breachId} />
        </Allotment.Pane>
      </Allotment>
    </>
  );
}

interface SnoozeButtonProps {
  alertId?: number;
  breachId?: number;
}

function SnoozeButton({ alertId, breachId }: SnoozeButtonProps) {
  const [snoozed, setSnoozed] = useSnoozed(alertId ?? 0, breachId ?? 0);

  function toggleSnooze() {
    if (snoozed) {
      setSnoozed(new Date().toISOString(), 0, false);
    } else {
      modalApi.showModal();
    }
  }

  const modalApi = useModal(() => (
    <SnoozeModal
      onSnooze={(durationMinutes, snoozed) => {
        setSnoozed(new Date().toISOString(), durationMinutes, snoozed);
      }}
    />
  ));

  return (
    <Button
      className={clsx('w-100', { 'text-secondary': snoozed })}
      size="md"
      purpose="flat"
      onClick={toggleSnooze}
      iconPosition={'start'}
    >
      {snoozed ? 'Unsnooze' : 'Snooze'}
      <em className="icon">{snoozed ? 'add_alert' : 'notifications_paused'}</em>
    </Button>
  );
}

function ToolsPanel() {
  const appState = useAppSelector(state => state.main);
  return (
    <header>
      <section className="flex-between flex-grow-1 px-16px px-lg-24px mt-8px mb-16px">
        <h1 className="h2">Real time alerts</h1>

        <article className="d-flex gap-16px">
          <button className="btn btn-flat btn-icon-end" type="button">
            Reset display
            <i className="icon">replay</i>
          </button>
          <div
            className="btn-group btn-group-toggle btn-group-md"
            data-toggle="buttons"
            role="group"
            aria-label="toggle buttons"
          >
            <input
              className="btn-check"
              type="radio"
              name="all"
              id="all"
              autoComplete="off"
              defaultChecked
            />
            <label className="btn btn-toggle-primary" htmlFor="all">
              All alerts
            </label>
            <input
              type="radio"
              className="btn-check"
              name="active"
              id="active"
              autoComplete="off"
            />
            <label className="btn btn-toggle-primary" htmlFor="active">
              Active
            </label>
          </div>
          <button
            className="btn btn-default btn-icon-end"
            type="button"
            onClick={() => downloadJson('state.json', appState)}
          >
            Export
            <i className="icon">save_alt</i>
          </button>
        </article>
      </section>
    </header>
  );
}
