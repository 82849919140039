import { EventEmitter } from 'events';
import { pEvent, type CancelablePromise } from 'p-event';
import pRetry, { type FailedAttemptError, type Options as RetryOptions } from 'p-retry';
import pTimeout from 'p-timeout';

import type { WsQuery, WsSubscriptionParameter } from '@/types/model/ws/query.ts';
import type {
  NotificationType,
  ResponseType,
  WsNotification,
  WsResponse,
} from '@/types/model/ws/response.ts';

export type WsEvents = {
  response: [ResponseType];
  notification: [NotificationType];
  error: [
    {
      message: string;
      title: string;
    },
  ];
  close: [];

  onSubscribe: [WsSubscriptionParameter];
  onUnsubscribe: [WsSubscriptionParameter];
};

export type WsEventEmitter = EventEmitter<WsEvents>;

const WebSocketTimeoutMs = 5000;

export interface IWebsocketConnection {
  sendAndWait(message: WsQuery): Promise<ResponseType>;

  unsubscribe(params: WsSubscriptionParameter): void;

  subscribeAndWaitForImage<R extends NotificationType = NotificationType>(
    message: WsSubscriptionParameter,
    filter: (notification: NotificationType) => notification is R,
  ): Promise<R>;
}

export class WebsocketConnection implements IWebsocketConnection {
  public eventEmitter: WsEventEmitter;
  private currentId = 2;

  static async create(url: string): Promise<WebsocketConnection> {
    const onFailedAttempt = (error: FailedAttemptError) => {
      console.log(
        `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left. Cause: ${JSON.stringify(error.cause)}`,
      );
    };

    const options: RetryOptions = {
      onFailedAttempt,
      retries: 3,
      factor: 2,
      minTimeout: 1000,
      maxTimeout: 5000,
    };

    return pRetry(() => WebsocketConnection.doCreate(url), options);
  }

  private static async doCreate(url: string): Promise<WebsocketConnection> {
    try {
      const ws = new WebSocket(url);
      const websocketConnection = new WebsocketConnection(ws);

      return new Promise((resolve, reject) => {
        ws.onopen = () => {
          resolve(websocketConnection);
        };
        ws.onerror = event => {
          reject(new Error('Error establishing websocket connection', { cause: event }));
        };
        ws.onclose = () => {
          websocketConnection.eventEmitter.emit('close');
        };
      });
    } catch (error) {
      return Promise.reject(new Error('Error establishing websocket connection', { cause: error }));
    }
  }

  private constructor(private webSocket: WebSocket) {
    this.eventEmitter = createWsEventEmitter();
    webSocket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      // console.log('Received message', data);
      if ('type' in data && 'payload' in data) {
        switch (data.type) {
          case 'NOTIFICATION': {
            const notification = data as WsNotification;
            this.eventEmitter.emit('notification', notification.payload);
            break;
          }
          case 'RESPONSE': {
            const response = data as WsResponse;
            this.eventEmitter.emit('response', response.payload);
            break;
          }
        }
      } else {
        console.error('Unknown event type', data);
      }
    };
  }

  private send(message: WsQuery): void {
    this.webSocket.send(JSON.stringify(message));
  }

  async sendAndWait(message: WsQuery): Promise<ResponseType> {
    return pTimeout(
      new Promise((resolve, reject) => {
        const messageId = message.payload.id;
        const listener = (payload: ResponseType) => {
          if (payload.id === messageId) {
            this.eventEmitter.off('response', listener);
            if (payload.success) {
              resolve(payload);
            } else {
              reject(new Error(payload.errorMessage));
            }
          }
        };
        this.eventEmitter.on('response', listener);
        this.send(message);
      }),
      {
        milliseconds: WebSocketTimeoutMs,
        message: 'Timeout while waiting for Websocket to respond',
      },
    );
  }

  unsubscribe(params: WsSubscriptionParameter): void {
    this.eventEmitter.emit('onUnsubscribe', params);
    this.send({
      type: 'REQUEST',
      payload: {
        id: this.currentId++,
        operation: 'UNSUBSCRIBE',
        parameter: params,
      },
    });
  }

  async subscribeAndWaitForImage<R extends NotificationType = NotificationType>(
    message: WsSubscriptionParameter,
    filter: (notification: NotificationType) => notification is R,
  ): Promise<R> {
    const id = this.currentId++;
    const responsePromise: CancelablePromise<R> = pEvent<WsEvents>(
      this.eventEmitter,
      'notification',
      filter,
    );
    await this.sendAndWait({
      type: 'REQUEST',
      payload: {
        id: id,
        operation: 'SUBSCRIBE',
        parameter: message,
      },
    });
    this.eventEmitter.emit('onSubscribe', message);
    return pTimeout(responsePromise, {
      milliseconds: WebSocketTimeoutMs,
      message: `Did not receive image for ${message.subject}`,
    });
  }
}

function createWsEventEmitter(): WsEventEmitter {
  const eventEmitter = new EventEmitter() as WsEventEmitter;
  eventEmitter.setMaxListeners(4);
  return eventEmitter;
}
