import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

import { getConfig } from '@/core/config/config.ts';

export function HelpCenter(): JSX.Element {
  const { contact, subject } = getConfig().helpCenter;
  return (
    <aside>
      <SgwtHelpCenter
        applicationId={import.meta.env.VITE_SPLASH_APP_ID}
        default-send-to={contact}
        mailSubject={subject}
        allowScreenshot={true}
      />
    </aside>
  );
}
