import { isFulfilled } from '@reduxjs/toolkit';
import { Button, Checkbox, HStack, Select } from '@sgme/ui';
import { useKey } from 'react-use';

import type { BreachCommentPost, BreachTypePatch } from '@/types/model/ws/generatedModel.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts';
import { apiSlice } from '@/store/slices/apiSlice.ts';
import { patchBreach, postComment } from '@/store/async-thunks/api-thunks.ts';
import { TextInput } from '@/web/components/bootstrap/TextInput.tsx';
import { useMethods } from '@/web/components/hooks/useMethods.ts';

const breachTypes = ['Limit calibration', 'Genuine Alert', 'Technical', 'Other'] as const;
type BreachType = (typeof breachTypes)[number];

interface DefaultClosingState {
  closingComment: true;
  breachType: Exclude<BreachType, 'Other'>;
  comment: string;
}

interface FreeClosingState {
  closingComment: true;
  breachType: 'Other';
  comment: string;
  freeTextType: string;
}

interface CommentState {
  closingComment: false;
  comment: string;
}

type ClosingState = FreeClosingState | DefaultClosingState;
type FormData = ClosingState | CommentState;

interface State {
  formData: FormData;
  loading: boolean;
}

function selectCanPost(state: State): boolean {
  return state.formData.comment !== '' && !state.loading;
}

function selectBreachType(state: ClosingState): string {
  if (!state.closingComment) {
    return '';
  }
  if (state.breachType === 'Other') {
    return state.freeTextType;
  }
  return state.breachType;
}

function createMethods(state: State) {
  return {
    setComment(comment: string): State {
      return { ...state, formData: { ...state.formData, comment } };
    },
    setClosing(closingComment: boolean): State {
      if (closingComment) {
        const formData: FormData = {
          ...state.formData,
          closingComment,
          breachType: 'Limit calibration',
        };
        return { ...state, formData };
      }
      const formData: FormData = {
        comment: state.formData.comment,
        closingComment,
      };
      return { ...state, formData };
    },
    setBreachType(breachType: BreachType): State {
      if (breachType === 'Other') {
        const formData: FormData = {
          ...state.formData,
          breachType,
          freeTextType: '',
        };
        return { ...state, formData };
      }
      const formData: FormData = {
        ...state.formData,
        breachType,
      };
      return { ...state, formData };
    },
    setFreeText(freeTextType: string): State {
      const formData: FormData = {
        ...state.formData,
        freeTextType,
      } as FormData;
      return { ...state, formData };
    },
    setLoading(loading: boolean): State {
      return { ...state, loading };
    },
    clearForm(): State {
      return { ...state, formData: { comment: '', closingComment: false } };
    },
  } as const;
}

export function CommentsForm({
  alertId,
  breachId,
  onCommentPosted,
  breachHasClosingComment,
}: {
  onCommentPosted: () => void;
  breachHasClosingComment: boolean;
  alertId: number;
  breachId: number;
}) {
  const dispatch = useAppDispatch();
  useKey(k => k.ctrlKey && k.key === 'Enter', handleSubmit);

  const initialState: State = {
    loading: false,
    formData: {
      comment: '',
      closingComment: false,
    },
  };
  const [state, stateMethods] = useMethods(createMethods, initialState);
  const formData = state.formData;

  const hasPermission = useAppSelector(state =>
    apiSlice.selectors.userHasPermission(state, 'WRITE'),
  );
  const canPost = selectCanPost(state);
  const closeButtonEnabled = !breachHasClosingComment;
  const postButtonEnabled = canPost && hasPermission;

  async function patchBreachDispatch(body: BreachTypePatch): Promise<void> {
    const result = await dispatch(patchBreach({ alertId, breachId, body }));
    if (!isFulfilled(result)) {
      throw result;
    }
  }
  async function postCommentDispatch(body: BreachCommentPost): Promise<void> {
    await dispatch(postComment({ alertId, breachId, body }));
  }

  async function handleSubmit() {
    if (formData.comment === '') {
      return;
    }

    stateMethods.setLoading(true);

    try {
      if (formData.closingComment) {
        await patchBreachDispatch({ breachType: selectBreachType(formData) });
      }
      await postCommentDispatch({
        comment: formData.comment,
        closingComment: formData.closingComment,
      });
    } finally {
      stateMethods.clearForm();
      stateMethods.setLoading(false);
      onCommentPosted();
    }
  }

  if (!hasPermission) {
    return null;
  }

  return (
    <article className="d-flex flex-column border-top pt-8px gap-8px">
      <textarea
        className="form-control"
        value={formData.comment}
        onChange={e => stateMethods.setComment(e.target.value)}
      />
      <div className="d-flex gap-1">
        {formData.closingComment && (
          <Select itemsAsObjects={breachTypes} onChange={stateMethods.setBreachType} />
        )}
        {formData.closingComment && formData.breachType === 'Other' && (
          <TextInput
            placeholder="Breach type..."
            name="breach-type"
            wrapperClassName="breach-type-input"
            maxLength={20}
            type="text"
            onChange={e => stateMethods.setFreeText(e.target.value)}
          />
        )}
      </div>

      <div className="flex-between">
        <Checkbox
          disabled={!closeButtonEnabled}
          checked={formData.closingComment}
          onCheckBoxChange={closingComment => stateMethods.setClosing(closingComment)}
          label="Closing comment"
        />
        <Button
          disabled={!postButtonEnabled}
          onClick={handleSubmit}
          loading={state.loading}
          type="submit"
        >
          Send
        </Button>
      </div>
    </article>
  );
}
