import { format } from 'date-fns';

export function formatDateTime(value: number | string | Date) {
  return `${format(value, 'dd LLL yyyy HH:mm:ss')} (${getTimezoneOffset()})`;
}

export function formatDate(value: number | string | Date) {
  return `${format(value, 'dd LLL yyyy')} (${getTimezoneOffset()})`;
}

function getTimezoneOffset(): string {
  const timezoneOffset = -new Date().getTimezoneOffset() / 60;
  if (timezoneOffset > 0) {
    return `UTC+${timezoneOffset}`;
  }
  return `UTC${timezoneOffset}`;
}
