import type { Breach } from '@/types/model/ws/generatedModel.ts';
import type { IWebsocketConnection } from '@/core/ws/WebsocketConnection.ts';
import type { AppStore } from '@/store/store.ts';
import { mainSlice } from '@/store/slices/mainSlice.ts';
import { fetchBreaches, fetchEvents } from '@/store/async-thunks/ws-thunks.ts';

interface PanelSub {
  unsubscribe: () => void;
  markedForDeletion: boolean;
  clearSelection: () => void;
  alertId: number;
}

export class SubscriptionManager {
  private breachPanelsSubsByAlertId: Record<number, PanelSub> = {};

  constructor(
    private store: AppStore,
    private wsConnection: IWebsocketConnection,
  ) {}

  getBreaches(alertId: number): Breach[] {
    return mainSlice.selectors.breachesByAlertId(this.store.getState(), alertId);
  }

  onBreachExpanded(
    alertId: number,
    updateBreachData: (data: Breach[]) => void,
    clearSelection: () => void,
  ) {
    const wsConnection = this.wsConnection;

    this.breachPanelsSubsByAlertId[alertId]?.unsubscribe();

    const unsubscribeStore = this.store.subscribe(() => {
      const breaches = mainSlice.selectors.breachesByAlertId(this.store.getState(), alertId);
      if (this.breachPanelsSubsByAlertId[alertId]?.markedForDeletion !== true) {
        updateBreachData(breaches);
      }
    });

    const unsubscribe = () => {
      unsubscribeStore();
      // UNSUBSCRIBE BREACH {alertId}
      this.wsConnection.unsubscribe({ subject: 'BREACH', alertId });
      delete this.breachPanelsSubsByAlertId[alertId];
    };

    // UNSUBSCRIBE BREACH {alertId}
    this.store.dispatch(fetchBreaches({ alertId, wsConnection }));

    this.breachPanelsSubsByAlertId[alertId] = {
      markedForDeletion: false,
      unsubscribe,
      clearSelection,
      alertId,
    };
  }

  onBreachSelected(alertId: number, breachId: number) {
    const selectedDetails = mainSlice.selectors.selectedDetails(this.store.getState());
    const previousBreachId = selectedDetails?.breachId;
    const wsConnection = this.wsConnection;

    this.store.dispatch(mainSlice.actions.setSelectedDetails({ breachId, alertId }));

    // UNSUBSCRIBE EVENT {breachId}
    if (previousBreachId !== undefined) {
      wsConnection.unsubscribe({
        subject: 'EVENT',
        breachId: previousBreachId,
      });
    }

    for (const panelSub of Object.values(this.breachPanelsSubsByAlertId)) {
      if (panelSub.markedForDeletion) {
        panelSub.unsubscribe();
      } else if (panelSub.alertId !== alertId) {
        panelSub.clearSelection();
      }
    }

    // SUBSCRIBE EVENT {breachId}
    this.store.dispatch(fetchEvents({ breachId, wsConnection }));
  }

  onBreachCollapsed(alertId: number) {
    if (this.breachPanelsSubsByAlertId[alertId] !== undefined) {
      this.breachPanelsSubsByAlertId[alertId].markedForDeletion = true;
    }
  }
}
