import { createSlice, nanoid, type PayloadAction } from '@reduxjs/toolkit';
import { assertUnreachable } from '@sgme/fp';

import type { IntlMessage } from '@/types/intl';
import type { GuiNotification } from '@/types/model/ws/model.ts';
import type { AppThunk } from '@/store/store.ts';
import { SnoozeManager } from '@/web/components/guardian/snooze/SnoozeManager.ts';

export type ToastSeverity = 'error' | 'info';
export type IntlMessageOrString = IntlMessage | string;

export interface BreachLink {
  alertId?: number;
  breachId?: number;
}

export interface ToastMessage {
  id: string;
  title: IntlMessageOrString;
  body?: IntlMessageOrString;
  link?: BreachLink;
  time: string;
  severity: ToastSeverity;
}

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ToastMessagePayload = PartialBy<Omit<ToastMessage, 'id' | 'time'>, 'title'>;

interface UiState {
  toastMessages: ToastMessage[];
}

const initialState: UiState = {
  toastMessages: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastMessagePayload>) => {
      state.toastMessages.push({
        ...action.payload,
        title: action.payload.title ?? action.payload.severity,
        id: nanoid(),
        time: new Date().toISOString(),
      });
    },
    deleteToast: (state, action: PayloadAction<string>) => {
      state.toastMessages = state.toastMessages.filter(
        toastMessage => toastMessage.id !== action.payload,
      );
    },
  },
  selectors: {
    toasts: state => state.toastMessages,
  },
});

export function handleGuiNotificationThunk(guiNotification: GuiNotification): AppThunk {
  return async dispatch => {
    const { alertId, breachId } = guiNotification;
    const snoozed = SnoozeManager.instance().isSnoozed(alertId, breachId, new Date().toISOString());
    console.log('Received notification', guiNotification, `snoozed=${snoozed}`);
    if (snoozed) {
      return;
    }

    switch (guiNotification.type) {
      case 'DESKTOP_NOTIFICATION': {
        sendNotification(
          guiNotification.title,
          { alertId, breachId },
          {
            body: guiNotification.body,
            // group notifications to avoid spam
            tag: `${alertId}-${breachId}`,
          },
        );
        break;
      }
      case 'TOAST': {
        const body = guiNotification.body;
        const toast: ToastMessagePayload = {
          body: body,
          link: { alertId, breachId },
          title: guiNotification.title,
          severity: 'info',
        };
        dispatch(uiSlice.actions.addToast(toast));
        break;
      }
      default:
        assertUnreachable(
          guiNotification.type,
          `Unhandled GUI Notification type ${guiNotification.type}`,
        );
    }

    if (guiNotification.sound !== undefined) {
      const sound = document.getElementById(guiNotification.sound) as HTMLMediaElement;
      if (sound !== null) {
        sound.pause();
        sound.currentTime = 0;
        await sound.play();
      } else {
        console.warn(`Cannot play sound ${guiNotification.sound}`);
      }
    }
  };
}

function sendNotification(message: string, link?: BreachLink, options?: NotificationOptions) {
  const notification = new Notification(message, options);
  notification.addEventListener('click', () => {
    if (link !== undefined && link.alertId !== undefined) {
      const search = new URLSearchParams();
      if (link.alertId !== undefined) {
        search.append('alertId', link.alertId.toString());
      }
      if (link.breachId !== undefined) {
        search.append('breachId', link.breachId.toString());
      }

      window.focus();
      history.replaceState(null, '', `/?${search}`);
    }
  });
}
