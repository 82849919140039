import type {
  ColDef,
  ColGroupDef,
  GridPreDestroyedEvent,
  GridReadyEvent,
  ICellRendererParams,
  IDetailCellRendererParams,
  IRowNode,
  SelectionChangedEvent,
} from '@ag-grid-community/core';

import type { Alert, Breach } from '@/types/model/ws/generatedModel.ts';
import { formatDateTime } from '@/core/utils/dateFormats.ts';
import { commonGridOptions } from '@/web/ag-grid/commonTable.ts';
import { SnoozeButton } from '@/web/components/guardian/snooze/SnoozeButton.tsx';
import type { SubscriptionManager } from '@/web/components/guardian/SubscriptionManager.ts';
import { BadgeRenderer } from '@/web/components/guardian/table/BadgeRenderer.tsx';
import { SeverityRenderer } from '@/web/components/guardian/table/SeverityRenderer.tsx';
import { StatusRenderer } from '@/web/components/guardian/table/StatusRenderer.tsx';

interface BreachContext {
  node: IRowNode<Alert>;
  alert: Alert;
}

export function getBreachDetailsCellRenderer(
  subscriptionManager: SubscriptionManager,
  onBreachSelected: (alertId: number, breachId: number) => void,
): (masterGridParams: ICellRendererParams<Alert>) => IDetailCellRendererParams<Alert, Breach> {
  return masterGridParams => {
    return {
      detailGridOptions: {
        ...commonGridOptions,
        rowSelection: {
          mode: 'singleRow',
          checkboxes: false,
          enableClickSelection: true,
        },
        columnDefs: [
          { field: 'id' },
          { field: 'breachType', cellRenderer: BadgeRenderer },
          {
            field: 'status',
            cellRenderer: StatusRenderer,
            filter: 'agSetColumnFilter',
          },
          {
            field: 'startInstant',
            valueFormatter: ({ value }) => formatDateTime(value),
          },
          { field: 'currentReached.percent' },
          { field: 'maxReached.percent' },
          { field: 'eventCount' },
          {
            field: 'worstSeverity',
            cellRenderer: SeverityRenderer,
            filter: 'agSetColumnFilter',
          },
          { field: 'comments', valueFormatter: ({ value }) => value.length },
          {
            headerName: 'Actions',
            maxWidth: 100,
            filter: false,
            sortable: false,
            cellClass: 'text-center',
            cellRenderer: (params: ICellRendererParams<Breach>) => {
              const alertId = params.data?.alertId;
              const breachId = params.data?.id;
              if (alertId === undefined || breachId === undefined) {
                return null;
              }

              return <SnoozeButton alertId={alertId} breachId={breachId} />;
            },
          },
        ] satisfies (ColDef<Breach> | ColGroupDef<Breach>)[],
        context: {
          node: masterGridParams.node.parent!,
          alert: masterGridParams.data!,
        } satisfies BreachContext,

        getRowId: params => params.data.id.toString(),

        onGridReady(event: GridReadyEvent<Breach, BreachContext>) {
          const context = event.context;
          const alertId = context.alert.id;

          function updateBreachData(breaches: Breach[]) {
            if (event.api.isDestroyed()) {
              console.warn('Called updater on destroyed grid', event.api.isDestroyed());
              return;
            }
            if (event.api.getGridOption('rowData') !== breaches) {
              event.api.updateGridOptions({
                rowData: breaches,
              });
            }
          }

          const clearSelection = () => {
            event.api.deselectAll();
          };
          subscriptionManager.onBreachExpanded(alertId, updateBreachData, clearSelection);
        },

        onGridPreDestroyed(event: GridPreDestroyedEvent<Breach, BreachContext>) {
          const context = event.context;
          const alertId = context.alert.id;
          subscriptionManager.onBreachCollapsed(alertId);
        },

        onSelectionChanged(event: SelectionChangedEvent<Breach, BreachContext>) {
          const selectedNodes = event.api.getSelectedNodes();
          if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const { id: breachId, alertId } = selectedNode.data!;
            if (alertId !== undefined && breachId !== undefined) {
              subscriptionManager.onBreachSelected(alertId, breachId);
              onBreachSelected(alertId, breachId);
            }
          }
        },

        // getRowClass: (params: RowClassParams<Breach, BreachContext>) => {
        //   const context = params.context;
        //   const alertId = context.alert.id;
        //   const breachId = params.data?.id;
        //
        //   console.log('getRowClass', { alertId, breachId });
        //   const now = new Date().toISOString();
        //   if (SnoozeManager.instance().isSnoozed(alertId, breachId, now)) {
        //     return 'text-secondary';
        //   }
        // },
      },

      getDetailRowData: params => {
        params.successCallback(subscriptionManager.getBreaches(params.data.id));
      },
    } as IDetailCellRendererParams<Alert, Breach>;
  };
}
